/* global $ */
import React, {Component} from 'react'
import helper from "../../helpers";


function Carousel(props){
    const slides = props.listing.map((row) =>
        <div className="carousel-item" key={row.id}>
            <div className="group-info-testimonials">
                <p>{helper.limit_text(row.comment, 50)}<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                <br/>{row.car_name}
                <b>{row.name}</b>
            </div>
        </div>
    );
    return <div id="testimonies" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            {slides}
        </div>
        <a className="carousel-control-prev" href="#testimonies" data-slide="prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </a>
        <a className="carousel-control-next" href="#testimonies" data-slide="next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
        </a>
    </div>;
}

class Carouselold extends Component{
  constructor(props) {
    super(props);
    this.state = { test: 0 };
  }
  componentDidMount() {

  }

  render() {
    return (
      <div id="testimonies" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">

                        <div className="carousel-item active">
                           <div className="group-info-testimonials">

                               <p>Hyundai Gabriel is a very reliable and recommandable Car dealer. They have very professional employees who ever prove by their doing that they really care about Customer's interest and satisfaction. The sales as well as the service departments are woooooW! Keep it Up!<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Hyundai Gabriel Anjou
                               <b>Gaston Djonga</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>I just purchased a 2020 Genesis G90 from Gabriel, and everyone I met was helpful, courteous and interested in my satisfaction.  My sales person was Kevin, a smart chap who realized very quickly I knew what I wanted and was ready to buy - so he let me continue to "sell ...<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Hyundai Gabriel Ouest
                               <b>Darin Crosby</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>(Translated by Google) Wonderful service. Mr. Nabil Awad and Fadi Malak gave me an excellent service, they found an arrangement that went with my budget quickly and efficiently. I recommend them 100%. Thank you for your top quality work.

(Original)
Merveilleux service. M. Nabil Awad et Fadi Malak m'ont donné un ...<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Hyundai Gabriel St-Jacques
                               <b>krystel lavoie diaz</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>(Translated by Google) A place where courtesy, respect for the buyer and competence are under one roof.

(Original)
Un endroit où la courtoisie, le respect de l’acheteur et la compétence sont sous un même toit.<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Kia Gabriel Nord
                               <b>Caroline Dubois</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>Accueil agréable   excellent service après vente  ma meilleur expérience a vie chez un dépositaire automobile  merci<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Lexus Gabriel Brossard
                               <b>michel hervieux</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>(Translated by Google) Thank you for the Mazda Gabriel St-Laurent experience. Thank you George Tayar for exceptional service while I was in the "shopping" stage.
Thanks to Ash Sharma and Carine Hayek, for your warm and friendly welcome. Happy with my choice, happy with your offer. I felt at home!
...<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Mazda Gabriel St-Laurent
                               <b>Elaine Bouchard</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>Honestly never been more happy with their service department. No one trying to up-sale anything if necessary or talk with understandable language. Everything is clear and transparent. I have 0 knowledge about cars but they make it in pedestrian language and human way. Highly recommend for everyone. Very flexible with hours ...<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Nissan Gabriel St-Jacques
                               <b>Zaro Dimitrov</b>
                            </div>
                        </div>
                                                               <div className="carousel-item ">
                           <div className="group-info-testimonials">

                               <p>Today I went to Toyota Gabriel Saint-Laurent for change my tires. Even in this terrible COVID situation, they still provide excellent service. Thanks a lot for Mr. Joe and Mr. Nino, their professional and efficiency service leave me deep impression. I am so glad to buy the car from Toyota ...<a href="https://www.gabriel.ca/fr/testimonials"><span style={{fontSize: "0.9em"}}> trouve plus</span></a></p>
                               <span>
                               &#11088;&#11088;&#11088;&#11088;&#11088;                                                 </span>

                               <br/>Toyota Gabriel St-Laurent
                               <b>MARK MA</b>
                            </div>
                        </div>
                                                  </div>


          <a className="carousel-control-prev" href="#testimonies" data-slide="prev">
              <i className="fa fa-angle-left" aria-hidden="true"></i>
          </a>
          <a className="carousel-control-next" href="#testimonies" data-slide="next">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
          </a>
      </div>
    );
  }
}

class Testimony extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "new",
            listing: [],
            error: null,
            loading: false
        };
    }
    runScripts(){
        $(this.refs.div).find('.carousel .carousel-item').removeClass("active");
        $(this.refs.div).find('.carousel .carousel-item:first').addClass("active");
        $(this.refs.div).find('.carousel').carousel();
    }

    componentDidUpdate(prevProps, prevState){
        this.runScripts();
    }

    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;

        helper.api_get_all("home/reviews", function(listing){
            that.setState({ listing: helper.shuffle(listing).slice(0,8),  loading: false});
        }, function(message){
            that.setState({ error: message, listing: false });
        });
    }

  render() {
    return (
      <div className="row justify-content-between group-carousel-testimonies" ref="div">
        <div className="col-12">
            <div className="container">
                <div className="row container-carousel-testimonies d-flex align-items-center">
                    <div className="col-12 col-sm-12 col-lg-4 box-info-gabriel text-center text-lg-left">
                        <h3>À propos de nous</h3>
                        <p><a href="https://www.gabriel.ca/fr/about-us" title="">Le Groupe de concessionnaires Gabriel réunit les plus grandes marques automobiles sous ses concessionnaires dans la région du Grand Montréal et de la Rive-Sud. </a></p>
                        <a href="https://www.gabriel.ca/fr/about-us" title="" className="learnmore">En savoir plus</a>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-8 box-carousel-testimonies">
                        <h3 className="text-center">Commentaires de nos clients:</h3>
                        {this.state.loading ? <div className="loading-box">
                            <div className="spinner-border text-info" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> : ""}
                        {this.state.listing.length > 0 ? <Carousel listing={this.state.listing}/> : ""}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

export default Testimony;
