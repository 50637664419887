/* global $ */
import React, {Component} from 'react'
import helper from './../../helpers'

function ListCars(props){
    const slides = props.listing.map((row) =>
        <li  key={row.id}>
            <div>
                <div className="usedImg">
                    <a href={row.url} title="">
                        <img src={row.image} alt=""/>
                    </a>
                </div>
                <div className="usedDesc">
                    <h3>{row.year} {row.make_name} {row.model_name_en} {row.submodel}</h3>
                    <p className="price_big_carousel">
                        Prix:
                        <span className="section-text-price section-text-price-week"> {row.price_fr}</span>
                    </p>
                </div>
            </div>
        </li>

    );
    return <ul>{slides}</ul>;
}

class Cars extends Component{
    constructor(props) {
        super(props);
        this.state = {
            type: props.type || "new",
            listing: [],
            error: null,
            loading: false
        };
    }
    runScripts(){
        helper.jcarousel(this.refs.div);
    }

    componentDidUpdate(prevProps, prevState){
        this.runScripts();
    }

    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;

        helper.api_get_all("home/cars", function(listing){
            that.setState({ listing: listing,  loading: false});
        }, function(message){
            that.setState({ error: message, listing: false });
        });
    }




  render() {
    return (
      <div className="jcarousel-wrapper" ref="div">
            {this.state.loading ? <div className="loading-box">
                <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> : ""}
            {this.state.listing.length > 0 ? <div className="jcarousel" data-jcarousel="true" data-jcarouselautoscroll="true">
                    <ListCars listing={this.state.listing}/>
            </div>: ""}

            <a href="#" className="jcarousel-control-prev" data-jcarouselcontrol="true">
                <i className="fa fa-angle-left" aria-hidden="true"></i>
            </a>
            <a href="#" className="jcarousel-control-next" data-jcarouselcontrol="true">
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
        </div>
    );
  }
}

export default Cars;
