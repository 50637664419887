import defaults from './TranslatesDefault';

const lng = localStorage.getItem("language") || "fr";

const translates = {
    fr: {
        locale: "fr",
        Default: Object.assign(defaults['fr'], {

        }),
        MessageTop: {
            "MESSAGE": "Notre département de service est maintenant ouvert, prenez rendez-vous en ligne."
        }
    },
    en: {
        locale: "en",
        Default: Object.assign(defaults['en'], {
            
        }),
        MessageTop: {
            "MESSAGE": "Our service department is now open, Book your appointment online."
        }
    },
};

export default lng === "en" ? translates["en"] : translates["fr"];