import React from 'react';
import './../assets/css/App.css';

import Messagetop from '../components/Layout/Messagetop'
import {Header, HeaderSimple} from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import Home from '../components/Home'
import ModelCarView from '../components/ModelCarView'

import { BrowserRouter, Route} from "react-router-dom";
import { TranslatorProvider, translate } from "react-translate"
import translations from '../constants/translations'

let lng = localStorage.getItem("language");
lng = ["fr", "en"].includes(lng) ? lng : "fr";
const lng_path = window.location.pathname.slice(0, 3);
if(lng_path === "/fr" && lng !== "fr" ) lng = "fr";

if(lng_path === "/en" && lng !== "en" ) lng = "en";
localStorage.setItem("language", lng);

function App() {
  return (
      <TranslatorProvider translations={translations}>
        <div id="wrapper" className="App">
            <Messagetop/>

            <BrowserRouter>
                <Route exact path={["/", "/home", "/:lang/index"]}>
                    <Header/>
                    <Home />
                </Route>
                <Route path="/:lang/widgets/:make">
                    <HeaderSimple/>
                    <ModelCarView  />
                </Route>
            </BrowserRouter>

          <Footer/>
        </div>
      </TranslatorProvider>
  );
}

export default App;
