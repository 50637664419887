import React, {Component} from 'react'
import LazyLoad from 'react-lazyload';
import helper from './../../helpers'
import { withRouter } from "react-router-dom";


class ModelCarView extends Component{
    constructor(props) {
        super(props);

        this.state = {
            make: ""
        };
    }

    componentDidMount() {
        console.log(this.props.match.params)

        this.setState({ make: this.props.match.params.make });
    }

    render() {

      return (
        <div id="content">
          <div className="container">
              <h1>{this.state.make}</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, numquam, voluptatibus! Atque dolorem doloremque dolores ducimus id ipsa ipsum iste iure, iusto, magnam maiores mollitia nostrum odio reiciendis, sint. Nam.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab alias asperiores dignissimos doloremque, fugiat iste maxime necessitatibus nobis non obcaecati quam sunt vel voluptas? Consectetur doloribus magnam qui quod tempora!</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus, error fuga minima neque numquam rerum tempore. Cumque dolore dolores earum enim eveniet exercitationem impedit minima, nisi nostrum numquam odit, recusandae.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, amet dolore doloribus dolorum, ducimus error facilis fuga id itaque libero modi quae rem tempore! Debitis esse in nam porro rerum!</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, numquam, voluptatibus! Atque dolorem doloremque dolores ducimus id ipsa ipsum iste iure, iusto, magnam maiores mollitia nostrum odio reiciendis, sint. Nam.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab alias asperiores dignissimos doloremque, fugiat iste maxime necessitatibus nobis non obcaecati quam sunt vel voluptas? Consectetur doloribus magnam qui quod tempora!</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus, error fuga minima neque numquam rerum tempore. Cumque dolore dolores earum enim eveniet exercitationem impedit minima, nisi nostrum numquam odit, recusandae.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, amet dolore doloribus dolorum, ducimus error facilis fuga id itaque libero modi quae rem tempore! Debitis esse in nam porro rerum!</p>

          </div>

        </div>
      );
    }
}

export default withRouter(ModelCarView);
