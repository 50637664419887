import React, {Component} from 'react'

class Footer extends Component{
    componentDidMount() {
 
    }
    componentDidUpdate(prevProps) {
     
    }
    componentWillUnmount() {

    }    
    handleChange(e) {

    }
  
    render() {
      return (
        <footer>
    <div className="container">
        <div className="row justify-content-center">
			<div className="col"><h3>Groupe Gabriel - Concessionnaires dans le Grand Montréal.</h3></div>
			<div className="w-100"></div>
            <div className="col-12 col-md-3 text-md-left text-center">
                <ul className="dealers">
                    <li><a href="https://www.acuragabriel.com/fr/index" title="" target="_blank">Acura Gabriel West-Island</a></li>
                    <li><a href="https://www.audianjou.com/fr/index" title="" target="_blank">Audi Anjou</a></li>
                    <li><a href="https://www.audiprestige.com/fr/index" title="" target="_blank">Audi St-Laurent</a></li>
                    <li><a href="https://www.audiwestisland.com/fr/index" title="" target="_blank">Audi West-Island</a></li>
                    <li><a href="https://fr.fordgabriel.com" title="" target="_blank">Ford Gabriel</a></li>
                    <li><a href="https://www.genesisdelouest.ca/fr/" title="" target="_blank">Genesis Gabriel de l'Ouest</a></li>
                    <li><a href="https://www.hondagabriel.com/fr/index" title="" target="_blank">Honda Gabriel</a></li>
                    <li><a href="https://www.hyundaigabrielanjou.com/fr/index" title="" target="_blank">Hyundai Gabriel Anjou</a></li>
                    <li><a href="https://www.hyundaigabrielddo.com/fr/index" title="" target="_blank">Hyundai Gabriel D.D.O.</a></li>
                    <li><a href="https://www.hyundaigabrielstjacques.com/fr/index" title="" target="_blank">Hyundai Gabriel St-Jacques</a></li>
                    <li><a href="https://www.infinitigabrielstleonard.com/fr/index" title="" target="_blank">Infiniti Gabriel St-Leonard</a></li>
                    <li><a href="https://www.kiagabrielnord.com/fr/index" title="" target="_blank">Kia Gabriel Nord</a></li>
                    <li><a href="https://www.kiagabrielouest.com/fr/index" title="" target="_blank">Kia Gabriel Ouest</a></li>
              </ul>
            </div>
            <div className="col-12 col-md-3 lborder text-md-left text-center">
                <ul className="dealers">
                    <li><a href="https://www.lexusgabrielbrossard.com/fr/index" title="" target="_blank">Lexus Gabriel Brossard</a></li>
                    <li><a href="https://www.lexusgabrielstlaurent.com/fr/index" title="" target="_blank">Lexus Gabriel St-Laurent</a></li>
                    <li><a href="https://www.lincolngabriel.com/fr/index" title="" target="_blank">Lincoln Gabriel</a></li>
                    <li><a href="https://www.mazdagabrielanjou.com/fr/index" title="" target="_blank">Mazda Gabriel Anjou</a></li>
                    <li><a href="https://www.mazdagabrielstjacques.com/fr/index" title="" target="_blank">Mazda Gabriel St-Jacques</a></li>
                    <li><a href="https://www.mazdagabrielstlaurent.com/fr/index" title="" target="_blank">Mazda Gabriel St-Laurent</a></li>
                    <li><a href="https://www.nissangabrielanjou.com/fr/index" title="" target="_blank">Nissan Gabriel Anjou</a></li>
                    <li><a href="https://www.nissangabrieljeantalon.com/fr/index" title="" target="_blank">Nissan Gabriel Jean-Talon</a></li>
                    <li><a href="https://www.nissangabrielpat.com/fr/index" title="" target="_blank">Nissan Gabriel P.A.T.</a></li>
                    <li><a href="https://www.nissangabrielplateau.com/fr/index" title="" target="_blank">Nissan Gabriel Plateau</a></li>
                    <li><a href="https://www.nissangabrielstjacques.com/fr/index" title="" target="_blank">Nissan Gabriel St-Jacques</a></li>
                    <li><a href="https://www.nissangabrielstleonard.com/fr/index" title="" target="_blank">Nissan Gabriel St-Léonard</a></li>
               </ul>
            </div>
            <div className="col-12 col-md-3 lborder text-md-left text-center">
                <ul className="dealers">
                    <li><a href="https://www.porscheprestige.com/?lang=fr" title="" target="_blank">Porsche Prestige</a></li>
                    <li><a href="https://www.toyotagabrielcentreville.com/fr/index" title="" target="_blank">Toyota Gabriel Centre-Ville</a></li>
                    <li><a href="https://www.toyotagabrielstlaurent.com/fr/index" title="" target="_blank">Toyota Gabriel St-Laurent</a></li>
                    <li><a href="https://www.vwgabrielstconstant.com/fr/" title="" target="_blank">Volkswagen Gabriel St-Constant</a></li>
                    <li><a href="https://www.vwgabrielstlaurent.com/fr/" title="" target="_blank">Volkswagen Gabriel St-Laurent</a></li>
                </ul>
				<hr className="text-md-left text-center" />
				<ul className="dealers">
                    <li><a href="https://www.gabrielharleydavidsonmtl.com/fr/index" title="" target="_blank">BMW Moto Gabriel</a></li>
                    <li><a href="https://www.gabrielbmwmoto.com/fr/index" title="" target="_blank">Gabriel Harley-Davidson Montréal</a></li>
                </ul>

            </div>
            <div className="col-12 col-md-3 lborder group-social-footer text-lg-left text-center">
                <p>Suivez nous</p>
                <ul className="social">
                    <li>
                        <a href="http://www.facebook.com/GroupeGabrielPrestigePresident" title="Facebook" target="_blank" className="first ">
                            <i className="fab fa-facebook fa-lg"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/GroupeGabriel" title="Twitter" target="_blank">
                            <i className="fab fa-twitter fa-lg"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://instagram.com/groupegabriel" title="Instagram" target="_blank">
                            <i className="fab fa-instagram fa-lg"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
      );
    }
}

export default Footer;