import React, {Component} from 'react'
import { translate } from "react-translate"
//import t from './../../services/Translator'
class Messagetop extends Component{

    render() {
        let { t } = this.props;
      return (
        <div id="messageDIV">
	<a href="https://www.gabriel.ca/fr/service">
		<div className="messageBar" >
				{t('MESSAGE')}</div>
	</a>
</div>
      );
    }
}
export default translate('MessageTop')(Messagetop);
//export default Messagetop;