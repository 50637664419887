/* global $ */
import React, {Component} from 'react'
import helper from "../../helpers";
import {translate} from "react-translate";

class Search extends Component{
    constructor(props) {
        super(props);
        this.state = {
            list_cars: [],
            list_motos: [],
            list_cars_models: [],
            list_motos_models: [],
            tab: "cars",
            type_car: "used",
            type_moto: "used",

            select_car_used: "all",
            select_car_new: "all",
            select_moto_used: "all",
            select_moto_new: "all"
        };
    }
    handleChangeCarUsed(event){
        const target = event.target;
        let car = this.state.list_cars.find(element => element.id === target.value);
        this.setState({
            select_car_used: target.value,
            list_cars_models: car.used_models || []
        });
    }
    handleChangeCarNew(event){
        const target = event.target;
        let car = this.state.list_cars.find(element => element.id === target.value);
        this.setState({
            select_car_new: target.value,
            list_cars_models: car.new_models || []
        });
    }
    handleChangeMotoUsed(event){
        const target = event.target;
        let moto = this.state.list_motos.find(element => element.id === target.value);
        this.setState({
            select_moto_used: target.value,
            list_motos_models: moto.used_models || []
        });
    }
    handleChangeMotoNew(event){
        const target = event.target;
        let moto = this.state.list_motos.find(element => element.id === target.value);
        this.setState({
            select_moto_new: target.value,
            list_motos_models: moto.new_models || []
        });
    }
    handleInputChangeCar(event){
        const target = event.target;
        this.setState({
            type_car: target.value.toString() === "2" ? "new" : "used",
            select_car_used: "all",
            select_car_new: "all",
            list_cars_models: []
        });
    }
    handleInputChangeMoto(event){
        const target = event.target;
        this.setState({
            type_moto: target.value.toString() === "2" ? "new" : "used",
            select_moto_used: "all",
            select_moto_new: "all",
            list_motos_models: []
        });
    }

    componentDidMount() {
        this.setState({ error: null, loading: true });
        let that = this;

        helper.api_get_object("lists", function(listing){
            that.setState({ list_cars: listing.cars, list_motos: listing.motos,  loading: false});
        }, function(message){
            that.setState({ error: message, listing: false });
        });

        $(this.refs.div).find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
           // console.log(e.target);
        })

    }

    render() {
        let optionsCars = this.state.list_cars
            .map((row, index) => <option key={row.id} value={row.id}>{row.name}</option>);
        let optionsMotos = this.state.list_motos
            .map((row, index) => <option key={row.id} value={row.id}>{row.name}</option>);

        let optionsCarsModels = this.state.list_cars_models
            .map((row, index) => <option key={row.id} value={row.id}>{row.name_fr}</option>);
        let optionsMotosModels = this.state.list_motos_models
            .map((row, index) => <option key={row.id} value={row.id}>{row.name_fr}</option>);
        let { t } = this.props;
      return (
        <div className="h-100 bg-dark" ref="div">
            <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link link-tab-promotion active" id="nav-new-tab" data-toggle="tab" href="#nav-new" role="tab" aria-controls="nav-new" aria-selected="true"><span className="sTab-Lbl">Trouver</span>AUTOS</a>
                    <a className="nav-item nav-link link-tab-promotion" id="nav-pre-owned-tab" data-toggle="tab" href="#nav-pre-owned" role="tab" aria-controls="nav-pre-owned" aria-selected="false"><span className="sTab-Lbl">Trouver</span>MOTOS</a>
                </div>
            </nav>
            <div className="tab-content px-sm-0" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-new" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="box-search-form box-search-form-home">
                        
                        <form id="search-form-int" method="post" className="search-form-car">
                            <input id="text_make-int" type="hidden" name="text-make" value="all"/>
                            <input id="option-int" type="hidden" name="option" value="car"/>
                            <div className="form-group group-radio-button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type-listing" id="inlineRadio2" value="2" onChange={this.handleInputChangeCar.bind(this)}/>
                                    <label className="form-check-label" htmlFor="inlineRadio2">{t("txt_new")}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type-listing" id="inlineRadio1" value="1" defaultChecked onChange={this.handleInputChangeCar.bind(this)}/>
                                    <label className="form-check-label" htmlFor="inlineRadio1">{t("lbl_pre_owned")}</label>
                                </div>

                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">{t("lbl_make")}:</label>
                                <select id="make_id-home" name="make_used" className="form-control make_s make_id-home-1" style={this.state.type_car !== "used" ? {display: "none"} : {display: "block"}} value={this.state.select_car_used} onChange={this.handleChangeCarUsed.bind(this)}>
                                    <option value="all">{t("lbl_all")}</option>
                                    {optionsCars}
                                </select>
                                <select id="make_id-home1" name="make_new" className="form-control make_s make_id-home-2" style={this.state.type_car === "used" ? {display: "none"} : {display: "block"}} value={this.state.select_car_new} onChange={this.handleChangeCarNew.bind(this)}>
                                    <option value="all">{t("lbl_all")}</option>
                                    <option value="1">Acura</option>
                                    <option value="3">Audi</option>
                                    <option value="11">Ford</option>
                                    <option value="13">Honda</option>
                                    <option value="15">Hyundai</option>
                                    <option value="16">Infiniti</option>
                                    <option value="20">Kia</option>
                                    <option value="22">Lexus</option>
                                    <option value="23">Lincoln</option>
                                    <option value="26">Mazda</option>
                                    <option value="31">Nissan</option>
                                    <option value="33">Porsche</option>
                                    <option value="41">Toyota</option>
                                    <option value="42">Volkswagen</option>
                                </select>
                            </div>
                            <div className="form-group group-dealer-vw" style={{display: "none"}}>
                                <label htmlFor="exampleFormControlSelect2">{t("txt_dealer")}:</label>
                                <select id="dealer_id-home1" name="dealer_vw" className="form-control make_s dealer_id-home-2">
                                    <option value="1">Volkswagen Gabriel St-Constant</option>
                                    <option value="2">Volkswagen Gabriel St-Laurent</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2">{t("lbl_model")}:</label>
                                <select id="model_id_car" name="model" className="form-control model_s for_select_home">
                                    <option value="all">{helper.he.decode(t("lbl_all_models2"))}</option>
                                    {optionsCarsModels}
                                </select>
                            </div>
                            <div className="form-group text-center mb-2">
                                <button className="searchButton" id="quickSearchInt" type="submit" >{t("lbl_find")}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-pre-owned" role="tabpanel" aria-labelledby="nav-pre-owned-tab">
                    <div className="box-search-form box-search-form-home">

                        <form id="search-form-moto" method="post" className="search-form-car">
                            <input id="text_make-int" type="hidden" name="text-make" value="all"/>
                            <input id="option-int" type="hidden" name="option" value="bike"/>
                            <div className="form-group group-radio-button">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type-listing-bike" id="inlineRadio2" value="2" onChange={this.handleInputChangeMoto.bind(this)} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Neuf</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="type-listing-bike" id="inlineRadio1" value="1" defaultChecked  onChange={this.handleInputChangeMoto.bind(this)} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Motos d'occasion</label>
                                </div>

                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">Marque:</label>
                                <select id="make_id-bike" name="make_used" className="form-control make_s make_id-bike-1" style={this.state.type_moto !== "used" ? {display: "none"} : {display: "block"}}  value={this.state.select_moto_used} onChange={this.handleChangeMotoUsed.bind(this)}>
                                    <option value="all">Toutes</option>
                                    {optionsMotos}
                                </select>
                                <select id="make_id-bike1" name="make_new" className="form-control make_s make_id-bike-2" style={this.state.type_moto === "used" ? {display: "none"} : {display: "block"}}  value={this.state.select_moto_new} onChange={this.handleChangeMotoNew.bind(this)}>
                                    <option value="all">Toutes</option>
                                    <option value="2">BMW</option>
                                    <option value="1">Harley-Davidson</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2">Modèle:</label>
                                <select id="model_id_bike" name="model" className="form-control model_s for_select_bike">
                                    <option value="all">Tous les modèles</option>
                                    {optionsMotosModels}
                                </select>
                            </div>
                            <div className="form-group text-center mb-2">
                                <button className="searchButton" id="quickSearchInt" type="submit" >{t("lbl_find")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}
export default translate('Default')(Search);